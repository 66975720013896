<template>
  <v-container>
    <v-row>
      <v-col>
        <v-breadcrumbs
          :items="breadcrumbs"
          class="pa-0"
          large
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            Nieuwe locatie
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="newLocation.title.value"
                  :error-messages="newLocation.title.errors"
                  autofocus
                  label="Naam"
                  @blur="validateTitle"
                  @input="newLocation.title.errors = []"
                />
              </v-col>

              <v-col v-if="organizations.loaded">
                <v-select
                  v-model="newLocation.organizationId.value"
                  :error-messages="newLocation.organizationId.errors"
                  :items="sortedOrganizations"
                  clearable
                  label="Organisatie"
                  @input="validateOrganization"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-btn
                  color="primary"
                  :loading="loading"
                  @click="validateAndCreate"
                >
                  Maak aan
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';

import { createBreadcrumbs } from '../../helpers/humanizer';

export default {
  name: 'LocationsNewPage',
  data() {
    return {
      loading: false,
      newLocation: {
        title: {
          value: '',
          errors: [],
        },
        organizationId: {
          value: '',
          errors: [],
        },
      },
      organizations: {
        loaded: false,
        value: [],
      },
    };
  },
  computed: {
    breadcrumbs() {
      return createBreadcrumbs(
        this.$store.state.app.dashboardHomeName,
        [
          {
            text: 'Locaties',
            disabled: false,
            exact: true,
            to: { name: 'wolks' },
          },
          {
            text: 'Nieuwe locatie',
            disabled: true,
            exact: true,
            to: { name: 'wolks' },
          },
        ],
        false,
      );
    },
    sortedOrganizations() {
      return [...this.organizations.value].sort((first, second) => {
        console.log();
        if (first.lowerCasedText > second.lowerCasedText) {
          return 1;
        }

        if (first.lowerCasedText < second.lowerCasedText) {
          return -1;
        }

        return 0;
      });
    },
  },
  beforeMount() {
    this.loadOrganizations();
  },
  methods: {
    loadOrganizations() {
      if (this.$store.state.auth.type !== 'admin') {
        return;
      }

      const url = `${config.VUE_APP_API_BASE_URL}/organizations`;

      axios({
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        method: 'get',
      })
        .then((response) => {
          this.organizations.value = response
            .data
            .organizations
            .map((organization) => ({
              text: organization.title,
              lowerCasedText: organization.title.toLowerCase(),
              value: organization._id,
            }));

          this.organizations.loaded = true;
        });
    },
    createLocation() {
      const url = `${config.VUE_APP_API_BASE_URL}/locations`;

      const organizationId = this.$store.state.auth.type === 'user'
        ? this.$store.state.auth.organizationId
        : this.newLocation.organizationId.value;

      this.loading = true;

      axios({
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        data: {
          title: this.newLocation.title.value,
          organizationId,
        },
        method: 'post',
      })
        .then((response) => {
          console.log(response.data);

          this.$router.push({ name: 'locations' });
        })
        .catch((error) => {
          if (
            error.response
              && error.response.status === 422
              && error.response.data
              && error.response.data.error === 'validationFailed'
          ) {
            if (error.response.data.validationErrors.simId.includes('already_found')) {
              this.newLocation.simId.errors.push('Already found in database');
            }

            if (error.response.data.validationErrors.sleeveId.includes('already_found')) {
              this.newLocation.sleeveId.errors.push('Already found in database');
            }

            console.log(error.response.data.validationErrors);
          } else if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }

          console.log(error.config);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    validateTitle() {
      this.newLocation.title.value = this.newLocation.title.value.trim();

      const newErrors = [];
      if (!this.newLocation.title.value) {
        newErrors.push('Is verplicht');
      }

      this.newLocation.title.errors = newErrors;
    },
    validateOrganization() {
      this.newLocation.organizationId.value = this.newLocation.organizationId.value.trim();

      const newErrors = [];
      if (!this.newLocation.organizationId.value) {
        newErrors.push('Is verplicht');
      }

      if (
        !this
          .organizations
          .value
          .map((o) => o.value)
          .includes(this.newLocation.organizationId.value)
      ) {
        newErrors.push('Niet gevonden in organisaties');
      }

      this.newLocation.organizationId.errors = newErrors;
    },
    validateAndCreate() {
      this.validateTitle();
      if (this.organizations.loaded) {
        this.validateOrganization();
      }

      if (this.newLocation.title.errors.length > 0) return;
      if (this.newLocation.organizationId.errors.length > 0) return;

      this.createLocation();
    },
  },
};
</script>
